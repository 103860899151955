@import "../../global.scss";
:local .base {
  .baseBox {
    display: flex;
    justify-content: space-between;
    background: #fff;
    margin: 15px;
    padding: 15px 0;
    .left {
      width: 225px;
    }
  }
  .right {
    border-left: 1px solid #ccc;
    flex: 1;
    padding-left: 40px;
    .title {
      font-size: 20px;
      color: #333;
      margin-bottom: 12px;
    }
  }
  .hidden {
    display: none;
  }
  .form {
    width: 320px;
    :global .ant-form-item-label {
      text-align: left;
      :global .ant-form-item-required:before {
        content: "";
      }
    }
    .tooltip {
      color: #ccc;
      margin-left: 5px;
    }
    .autoLogin {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .autoLoginTitle {
        flex: 1;
        text-align: left;
      }
      .forgetPassword {
        width: 150px;
        text-align: right;
      }
    }
    .verificationCode {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .verificationCodeInput {
        flex: 1;
      }
      .verificationCodeBtn {
        width: 150px;
        text-align: right;
      }
    }
    .verificationCodePic {
      display: flex;
      align-items: center;
      .input {
        flex: 1;
      }
      .img {
        margin-left: 10px;
        width: 120px;
        height: 40px;
        cursor: pointer;
      }
    }
    .autoLogin {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .checkBox {
        flex: 1;
        text-align: left;
      }
      .checkBoxForget {
        width: 120px;
        text-align: right;
      }
    }
    .crop {
      width: 60px;
      position: absolute;
      top: 34px;
      left: 150px;
      padding: 0px 10px;
      border: 1px solid #ccc;
      height: 30px;
      line-height: 30px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      color: #666;
    }
  } //安全设置
  .security {
    .loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
    .box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 50px;
      border-bottom: 1px solid #e8e8e8;
      padding: 15px 0;
      .securityLeft {
        font-size: 16px;
        flex: 1;
        .securityTitle {
          color: rgba(0, 0, 0, 0.65);
          margin-bottom: 5px;
        }
        .securityDetail {
          color: rgba(0, 0, 0, 0.45);
        }
        .green {
          color: #52c41a;
        }
      }
      .securityRight {
        margin-right: 10px;
      }
      .securityIcon {
        width: 48px;
        margin-right: 16px;
        height: 48px;
        line-height: 48px;
        .icon {
          font-size: 48px;
        }
      }
      .securityDing {
        width: 46px;
        margin-right: 18px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1890ff;
        border-radius: 2px;
        .icon {
          font-size: 36px;
        }
      }
    }
  }
}
