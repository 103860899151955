body {
}
.flex {
  display: flex;
}

.flexWrap {
  flex-wrap: wrap;
}
.wrap {
  flex-wrap: wrap;
}

.justifySpaceBetween {
  justify-content: space-between;
}

.justifySpaceArrow {
  justify-content: space-around;
}

.justifyCenter {
  justify-content: center;
}

.alignItemsCenter {
  align-items: center;
}

.alignItemsStart {
  align-items: flex-start;
}

.alignItemsEnd {
  align-items: flex-end;
}

.fontFamily {
}
.a-center {
  align-items: center;
}
.j-center {
  justify-content: center;
}
.j-around {
  justify-content: space-around;
}
.j-between {
  justify-content: space-between;
}

.hidden {
  display: none;
}
.cursor {
  cursor: pointer;
}
@keyframes titleLine {
  0%,
  25% {
    -webkit-transform: translateX(-64px);
    transform: translateX(-64px);
  }
  75%,
  to {
    -webkit-transform: translateX(376px);
    transform: translateX(376px);
  }
}
