@import "../../../global.scss";
.main {
  position: relative;
}
.content {
  position: relative;
  margin: 15px;
  padding: 15px 50px;
  background: #ffffff;
  height: 84vh;
  .name {
    font-size: 16px;
    color: #333;
    line-height: 40px;
    margin-bottom: 10px;
  }
}
.editor {
  border: 1px solid #7f7f7f;
  width: 60vw;
  height: 90%;
  & > div {
    height: 100% !important;
  }
}
.editorLoading {
  width: 60vw;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
