@import '../global.scss';
.main {
  position: relative;
  min-height: 100vh;
  background: url('../../images/home/bg.jpg') no-repeat;
  background-size: cover;
}
.content {
  position: relative;
  min-height: 100vh;
  background: url('../../images/home/bg_lines.png') no-repeat;
  background-size: cover;
}
.header {
  height: 800x;
  .img {
    width: 100%;
    object-fit: contain;
  }
}

.item {
  position: relative;
  margin: 0 12vw;
  border: 1px solid #bba06a;
  background: #fff;
  border-radius: 10px;
  padding: 0 80px;
  z-index: 1;
  margin-top: 160px;
  .theme {
    position: relative;
    top: -65px;
    height: 130px;
    border-radius: 66px;
    margin: 0 auto;
    border: 1px solid #bba06a;
    box-shadow: 0 7px 2px #e7d0a2;
    background: #fff;
    max-width: 560px;
    z-index: 10;
    .title {
      text-align: center;
      line-height: 130px;
      font-size: 56px;
      color: #5d4022;
      padding: 0 40px;
    }
    &:before {
      content: '';
      position: absolute;
      left: 40px;
      top: 50%;
      transform: translateY(-50%);
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: rgba(93, 64, 34, 0.3);
    }
    &:after {
      content: '';
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: rgba(93, 64, 34, 0.3);
    }
  }
  .descItem {
    margin-bottom: 50px;
    .title {
      font-size: 32px;
      color: #5d4022;
    }
  }
  .desc {
    position: relative;
    font-size: 32px;
    color: rgba(93, 64, 34, 0.6);
    line-height: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    .interval {
      margin-left: 20px;
    }
  }
  .descMore {
    -webkit-line-clamp: inherit;
  }
  .more {
    position: relative;
    font-size: 32px;
    color: #5d4022;
    line-height: 3;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    bottom: 0;
    cursor: pointer;
    .icon {
      position: relative;
      top: 2px;
      width: 19px;
      height: 19px;
      margin-left: 20px;
      border-top: 3px solid #5d4022;
      border-right: 3px solid #5d4022;
      transform: rotate(45deg);
    }
    &:hover {
      color: rgba(93, 64, 34, 0.8);
    }
  }
  &.contact {
    margin-top: 160px;
  }
  &.idea {
    padding-bottom: 40px;
  }
}
.qrList {
  position: relative;
  margin-top: 120px;
  z-index: 1;
  padding-bottom: 300px;
  .qrItem {
    margin-right: 120px;
    &:last-child {
      margin-right: 0;
    }
    width: 300px;
    .img {
      width: 300px;
      margin-bottom: 30px;
    }
    .title {
      text-align: center;
      font-size: 32px;
      color: #5d4022;
      line-height: 2;
    }
  }
}
.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  .img {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  @import './indexMax767.module.scss';
}
