@import '../global.scss';
.main {
  position: relative;
  min-height: 100vh;
  background: url('../../images/home/bg.jpg') no-repeat;
  background-size: cover;
}
.content {
  position: relative;
  min-height: 100vh;
  background: url('../../images/home/bg_lines.png') no-repeat;
  background-size: cover;
}
.header {
  height: auto;
  .img {
    width: 100%;
    object-fit: contain;
  }
}

.item {
  margin: 0 15px;
  border-radius: 10px;
  padding: 0 15px;
  z-index: 1;
  margin-top: 60px;
  .theme {
    top: -25px;
    width: 80%;
    height: 50px;
    border-radius: 66px;
    .title {
      line-height: 50px;
      font-size: 16px;
    }
    &:before {
      left: 20px;
      width: 15px;
      height: 15px;
    }
    &:after {
      right: 20px;
      width: 15px;
      height: 15px;
    }
  }
  .descItem {
    margin-bottom: 18px;
    .title {
      font-size: 13px;
    }
  }
  .desc {
    position: relative;
    font-size: 13px;
    line-height: 1.5;
    .interval {
      margin-left: 20px;
    }
  }
  .more {
    font-size: 13px;
    color: #5d4022;
    .icon {
      top: 1px;
      width: 8px;
      height: 8px;
      margin-left: 6px;
      border-top: 1px solid #5d4022;
      border-right: 1px solid #5d4022;
    }
  }
  &.contact {
    margin-top: 70px;
  }
  &.idea {
    padding-bottom: 0;
  }
}
.qrList {
  margin-top: 34px;
  z-index: 1;
  padding-bottom: 72px;
  .qrItem {
    margin-right: 40px;
    width: 100px;
    .img {
      width: 79px;
      margin: 0 auto;
      display: block;
    }
    .title {
      margin-top: 8px;
      font-size: 13px;
      line-height: 1;
    }
  }
}
.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  .img {
    width: 100%;
  }
}
