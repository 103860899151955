@import '../../global.scss';
.main {
  position: relative;
  .content {
    background: #fff;
    padding: 15px;
    margin: 15px;
  }
  .list {
    margin: 30px 0;
    .detail {
      width: 100%;
    }
    .btn {
      margin-top: 80px;
    }
  }
}
