@import '../../global.scss';
.form {
  position: relative;
  :global {
    .ant-upload-list-picture-card .ant-upload-list-item {
      & > div {
        height: 100%;
      }
    }
  }
}
