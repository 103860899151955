@import "../../global.scss";

:local .nav {
  font-size: 16px;
  height: 55px;
  text-align: left;
  line-height: 55px !important;
  border-bottom: 1px solid rgba(0, 21, 41, 0.08);
  margin-bottom: 15px;
  background: #fff;
  padding: 0 30px !important;
}
