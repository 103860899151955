@import '../../global.scss';
:local .login {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: #f0f2f5;
  background-image: url('../../../images/common/loginBg.svg');
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  padding-top: 6%;
  .loginBox {
    height: 1600px;
    padding: 0 15px;
  }
  .logo {
    width: 200px;
    max-height: 80px;
    margin: 15px auto 30px;
    overflow: hidden;
    .logoImg {
      width: 100%;
    }
  }
  .title {
    font-size: 16px;
    color: #666;
    margin: 15px 0 30px;
  }
  .form {
    .tooltip {
      color: #ccc;
      margin-left: 5px;
    }
    .autoLogin {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .autoLoginTitle {
        flex: 1;
        text-align: left;
      }
      .forgetPassword {
        width: 150px;
        text-align: right;
      }
    }
    .verificationCode {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .verificationCodeInput {
        flex: 1;
      }
      .verificationCodeBtn {
        width: 120px;
        text-align: center;
        margin-left: 10px;
      }
    }
    .verificationCodePic {
      display: flex;
      align-items: center;
      .input {
        flex: 1;
      }
      .img {
        margin-left: 10px;
        width: 120px;
        height: 40px;
        cursor: pointer;
      }
    }
    .autoLogin {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .checkBox {
        flex: 1;
        text-align: left;
      }
      .checkBoxForget {
        width: 120px;
        text-align: right;
      }
    }
  }
  .submit {
    position: relative;
    .btn {
      width: 100%;
    }
    .otherLoginStyle {
      height: 45px;
      line-height: 45px;
      text-align: left;
      display: flex;
      align-items: center;
      margin-top: 15px;
      justify-content: space-between;
      .iconBox {
        display: flex;
        flex: 1;
      }
      .icon {
        color: #fff;
        background: #d7dbe0;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: block;
        line-height: 29px;
        text-align: center;
        font-size: 17px;
        margin-left: 10px;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
        &:hover {
          color: #1890ff;
          background: #fff;
        }
      }
    }
  }
  .footer {
    margin-bottom: 30px;
    .img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    a {
      font-size: 14px;
      color: #9e9e9e;
    }
  }
}
