@import '../../global.scss';
:local .categoryBox {
  background: #fff;
  margin: 15px;
  flex-direction: column;
  padding: 10px 15px;
  .treeBox {
    flex: 1;
    margin-top: 20px;
    .treeNode {
      width: 100%;
      padding: 15px 0;
      .nodeItem {
        justify-content: space-between;
        padding: 0 20px;
        align-items: center;
        .title {
          font-size: 16px;
        }
        .addChild {
          padding: 0px 10px;
          height: 30px;
          margin: 0 5px;
        }
      }
    }
  }
  .addCategoryBtn {
    margin: 20px 10px;
    background: #0089ff;
    color: #fff;
    width: 120px;
    border-color: transparent;
  }
  :global .ant-tree li {
    .ant-tree-node-content-wrapper:hover,
    ::after {
      background-color: transparent !important;
    }
  }

  :global .ant-tree li :global .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: transparent !important;
  }
}
