@import "../global.scss";
.main {
  .title {
    font-size: 14px;
    height: 55px;
    text-align: left;
    line-height: 55px;
    border-bottom: 1px solid rgba(0, 21, 41, 0.08);
    margin-bottom: 15px;
    background: #fff;
    padding: 0 30px;
  }
}

.tableHeader {
  padding: 15px;
  margin: 15px 15px 0;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .operationList {
    & > .item {
      margin: 0 10px;
    }
  }

  .search {
    width: 450px;
    font-size: 14px;
    color: #fff;
  }
}

.table {
  background: #fff;
  padding: 15px;
  margin: 0 15px 15px;

  .avatar {
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
}
.btn {
  cursor: pointer;
  margin: 0 5px;
  // color: #1890ff;
}
.filters {
  padding: 15px;
  margin: 15px 15px 0;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search {
    width: 450px;
    font-size: 14px;
    color: #fff;
  }
  .selectDate {
    width: 450px;
  }
  .item {
    .drown {
      margin-left: 15px;
    }
    .itemAdd {
      color: #fff;
      margin-left: 3px;
    }
  }
}
.filterList {
  position: relative;
  & > .item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .title {
      font-size: 14px;
      color: #666;
      padding-right: 10px;
      width: 80px;
      text-align: right;
    }
    .condition {
      width: 150px;
      margin-right: 10px;
    }
    .input {
      flex: 1;
    }
  }
  .hidden {
    display: none;
  }
  .age {
    display: flex;
    flex: 1;
    align-items: center;
    .ageInput {
      padding: 0 5px;
    }
    .slider {
      flex: 1;
    }
  }
}
