@import '../../global.scss';
.main {
  position: relative;
}
.content {
  margin: 15px;
  padding: 30px;
  background: #fff;
  .list {
    position: relative;
    padding-bottom: 30px;
    .item {
      margin-bottom: 30px;
      border-bottom: 1px solid rgb(232, 232, 232);
      &:last-child {
        border-bottom: none;
      }
      .title {
        margin-bottom: 20px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 700;
        font-size: 16px;
        line-height: 1.5;
      }
      .description {
        width: 1000px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .detail {
        .priceList {
          width: 100%;
          .priceItem {
            width: 30%;
            margin-right: 3%;
            margin-bottom: 20px;
            .labelPar {
              margin-bottom: 10px;
              .label {
                color: rgba(0, 0, 0, 0.85);
                font-weight: 400;
                font-size: 14px;
                line-height: 1.5;
                white-space: nowrap;
                width: 85px;
                text-align: right;
                &:after {
                  position: relative;
                  top: -0.5px;
                  margin: 0 8px 0 2px;
                  content: ' : ';
                }
              }
              .desc {
                flex: 1;
                color: rgba(0, 0, 0, 0.65);
                font-size: 14px;
                line-height: 1.5;
              }
            }
          }
        }
      }
      .desc {
        .term {
          width: 30%;
          margin-bottom: 16px;
          margin-right: 1%;
        }
        .label {
          color: rgba(0, 0, 0, 0.85);
          font-weight: 400;
          font-size: 14px;
          line-height: 1.5;
          white-space: nowrap;
          &:after {
            position: relative;
            top: -0.5px;
            margin: 0 8px 0 2px;
            content: ' : ';
          }
        }
        .detail {
          color: rgba(0, 0, 0, 0.65);
          font-size: 14px;
          line-height: 1.5;
          .img {
            width: 100px;
            height: 100px;
            object-fit: cover;
          }
        }
      }
    }
  }
}
