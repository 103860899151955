@import "../global.scss";
.main {
  position: relative;
}

.header {
  margin: 15px;
  margin-bottom: 0;
  padding: 15px;
  background: #fff;
  height: 54px;
  .breadcrumb {
    line-height: 54px;
  }
}
.info {
  margin: 0 15px;
  padding: 15px;
  background: #fff;
  .detail {
    flex: 1;
    padding-left: 30px;
    .name {
      font-size: 20px;
      color: #666;
      height: 58px;
      line-height: 58px;
    }
    .desc {
      font-size: 16px;
      color: #666;
      height: 58px;
      line-height: 58px;
    }
  }
  .approval {
    padding: 0 30px;
    .title {
      font-size: 16px;
      color: #666;
    }
    .desc {
      font-size: 30px;
      color: #333;
      font-weight: 600;
      text-align: center;
    }
  }
}
.work {
  margin: 15px 15px 0;
  .theme {
    height: 56px;
    line-height: 56px;
    border-bottom: 1px solid rgba(233, 233, 233, 1);
    padding: 0 25px;
    .title {
      font-size: 16px;
      color: #333;
      font-weight: 650;
    }
    .desc {
      font-size: 16px;
      color: #1689ff;
    }
  }
  .list {
    padding: 25px;
    font-size: 16px;
    color: #333;
    .desc {
      color: #1689ff;
    }
    .myCheck {
      text-align: right;
      .count {
        margin-left: 5px;
      }
    }
  }
  .wait {
    background: #fff;
    margin-right: 15px;
  }
  .myWork {
    background: #fff;
  }
}
